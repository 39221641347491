<template>
    <div>
        <myHead/>
    <div style="margin:20px" >
        <div style="padding-top:4px">
            <!-- <a-date-picker @change="query" :allowClear="false" style="margin-left:0px" v-model:value="queryParam.date" /> -->
            <a-range-picker @change="query"  :placeholder="['开始日期', '结束日期']" style="font-size:14px;margin-left:0px"  v-model:value="queryParam.createTimes" />
            <div style="float:right">
                    <a-tooltip placement="bottom">
                    <template #title>
                        <span>跳转到免登录实时回单链接</span>
                    </template>
                    <a-button style="display:inline-block;width:140px;margin-top:0px;margin-bottom:0px;margin-left:20px" @click="receiptUrl"  class="otherBtn"  >
                        实时回单链接
                    </a-button>
                </a-tooltip>
                <a-tooltip placement="bottom">
                    <template #title>
                        <span>合并所有店铺导出报表</span>
                    </template>
                    
                    <a-button style="display:inline-block;width:100px;margin-top:0px;margin-bottom:0px;margin-left:20px" @click="downloadAll"  class="loginBtn"  >
                        合并下载
                    </a-button>
                </a-tooltip>

            </div>
        </div>
        <div style="margin-top:20px">
            <a-table :loading="loading"  rowKey="ShopId"   :pagination="false" :dataSource="list" :columns="columns" >
                <template #shopName="{ text }">
                    <b v-if="text=='合计'" style="text-align:center">合计</b>
                    <span v-else>{{ text }}</span>
                </template>
                <template #num="{ text }">
                    <a-tag color="#2db7f5" >{{text}}</a-tag>
                </template>
                <template #orderNum="{ text,record }">
                    <a-tag color="#f56c6c" v-if="text<record.Num">{{text}}</a-tag>
                        <a-tag color="#87d068" v-else>{{text}}</a-tag>
                </template>
                <template #advanceMoney="{ text }">
                     <b>{{text.toFixed(2) }}</b>
                </template>
                <template #actualCommission="{ text }">
                     <b>{{text.toFixed(2) }}</b>
                </template>
                <template #receivable="{ text }">
                     <b>{{text.toFixed(2) }}</b>
                </template>
                <template #action="{ record }">
                    <a-button v-if="record.ShopId>0" @click="download(record.ShopId)">下载报表</a-button>
                </template>

            </a-table>
        </div>

    </div>
    </div>
</template>
<script>
import {  ref } from 'vue';
import { taskGetShopReportDownloadReq,uri,memberGetMemberIdGet} from '@/request.js';
import moment from 'moment';
import myHead from '../components/myHead'

function formatDate(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
}


// import { useRouter } from 'vue-router';
// import { message } from 'ant-design-vue';
import {  } from '@ant-design/icons-vue';

export default {
    components: {
        myHead
    },
    setup(){
        let memberId=0;
        memberGetMemberIdGet({},(res)=>{
            memberId=res.Result;
        })
        const queryParam=ref({
            queryTxt:"",
            createTimes:[moment(formatDate(new Date()), 'YYYY/MM/DD'), moment(formatDate(new Date()), 'YYYY/MM/DD')],
        })
        const operateing=ref(false);
        const tableHeight=ref(0);
        tableHeight.value=document.documentElement.clientHeight-260;
        const loading=ref(false);
        const list=ref([]);
        const query=()=>{
            loading.value=true;
            let array=[];
            array.push(formatDate(new Date(queryParam.value.createTimes[0])));
            array.push(formatDate(new Date(queryParam.value.createTimes[1])));
            taskGetShopReportDownloadReq({
                createTimes:JSON.stringify(array),
                queryTxt:queryParam.value.queryTxt,
            },(res)=>{
                list.value=res.Result;
                loading.value=false;
            })
        };
        const downloadAll=()=>{
            let array=[];
            array.push(formatDate(new Date(queryParam.value.createTimes[0])));
            array.push(formatDate(new Date(queryParam.value.createTimes[1])));
            window.open(uri+"/task/GetMemberExl?memberId="+memberId+"&createTimes="+JSON.stringify(array));
        }
        const download=(id)=>{
            let array=[];
            array.push(formatDate(new Date(queryParam.value.createTimes[0])));
            array.push(formatDate(new Date(queryParam.value.createTimes[1])));
            window.open(uri+"/task/GetExl?memberId="+memberId+"&shopId="+id+"&createTimes="+JSON.stringify(array));
        }
        const receiptUrl=()=>{
            let token = localStorage.getItem("token");
            window.open(window.location.origin+"/#/receipt?queryToken="+token);
        }
        query();
        return{
            receiptUrl,
            downloadAll,
            download,
            queryParam,
            operateing,
            tableHeight,
            loading,
            list,
            query,
            columns: [
                // {
                //     title: '商家名称',
                //     dataIndex: 'MemberName',
                //     key: 'memberName',
                // },
                {
                    title: '店铺名称',
                    dataIndex: 'ShopName',
                    key: 'shopName',
                    slots: { customRender: 'shopName' },
                },
                {
                    title: '发布单数',
                    dataIndex: 'Num',
                    key: 'num',
                    slots: { customRender: 'num' },
                },
                {
                    title: '完成单数',
                    dataIndex: 'OrderNum',
                    key: 'orderNum',
                    slots: { customRender: 'orderNum' },
                },
                {
                    title: '货款',
                    dataIndex: 'AdvanceMoney',
                    key: 'advanceMoney',
                    slots: { customRender: 'advanceMoney' },
                },
                {
                    title: '佣金',
                    dataIndex: 'ActualCommission',
                    key: 'actualCommission',
                    slots: { customRender: 'actualCommission' },
                },
                {
                    title: '合计',
                    dataIndex: 'Receivable',
                    key: 'receivable',
                    slots: { customRender: 'receivable' },
                },
                {
                    title: '操作',
                    key: 'action',
                    slots: { customRender: 'action' },
                },
            ],
        }
    }
}
</script>
